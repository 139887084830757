import './App.css';
import Home from './Home/Home';
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { deleteCookie, getCookie } from './Common/CookieHelper';
import ReactLoading from 'react-loading';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Service from './Networking/networkutils';
import errorMessage from './Common/ErrorMessage';
import { Box } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import BookingPage from './Home/BookingPage';

const bbColorTheme = createTheme({
  palette: {
    primary: {
      main: '#007681',
    },
    secondary: {
      main: '#EEBE86',
    },
    ternary: {
      main: '#33cc33',
    },
    red: {
      main: '#ff3333',
    },
  }
})

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }
  componentDidMount() {
    var onlyBasic = false
    // alert(getCookie("bb_ts_token"))
    if (getCookie("bb_ts_token") && getCookie("bb_ts_access") && getCookie("bb_ts_user")) {
      var access = JSON.parse(getCookie("bb_ts_access"))
      // console.log(access)
      if (access && access.access && access.access.l20a && access.access.l20a.l20a_a) {
        onlyBasic = true
        this.checkToken();
      } else if (access && access.access && access.access.l20a && access.access.l20a.l20a_c) {
        this.checkToken();
      } else if (access && access.access && access.access.l20a && access.access.l20a.l20a_b) {
        this.checkToken();
      } else {
        if (window.location.hostname.includes("pms.bbparam.com")) {
          // alert("access Error")
          window.location.href = 'https://bbparam.com/pms/auth/?url=' + window.location.href
        }

      }
      if (access && access.access && access.access.l20a && (access.access.l20a.l20a_b || access.access.l20a.l20a_c)) {
        onlyBasic = false
      }
      if (onlyBasic) {
        if (!(window.location.href.includes("/user/book"))) {
          window.location.href = "/user/book"
        }
       
      }
    } else {
      // alert("Auth Error")
      if (window.location.hostname.includes("pms.bbparam.com")) {
        window.location.href = 'https://bbparam.com/pms/auth/?url=' + window.location.href
      }
    }
  }

  checkToken() {
    Service.get(`/bb/api/token/valid/check/`, {
      headers: {
        Authorization: "Token " + getCookie("bb_ts_token"),
      },
    })
      .then(res => {
        this.setState({ isLoading: false })
      })
      .catch(error => {
        // alert("Login Error!")
        console.log(error.response.status)
        if (error === undefined) {
          errorMessage(this.props, error)
        } else if (error && (error.status === 401 || (error.response && error.response.status === 401))) {
          deleteCookie("bb_ts_token");
          console.log(window.location.hostname)
          if (window.location.hostname.includes("pms.bbparam.com")) {
            window.location.href = 'https://bbparam.com/pms/auth/?url=' + window.location.href
          }
        }
      });
  }

  render() {
    return (
      <div >
        {(this.state.isLoading) ? (
          <Box display="flex" justifyContent="center"><ReactLoading color='#007681' /></Box>) : (
          <ThemeProvider theme={bbColorTheme}>
            <SnackbarProvider maxSnack='3'>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/user/book" element={<BookingPage />} />
                </Routes>
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        )}
      </div>
    )
  }
}

export default withSnackbar(App)
