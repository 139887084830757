import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import { getCookie } from '../Common/CookieHelper';
import errorMessage from '../Common/ErrorMessage';
import Service from '../Networking/networkutils'

const token = getCookie("bb_ts_token");

class IssuesRised extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: []
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/pms/slot/block/slot_block_data/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    data: res.data,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    putData(data) {
        // data.slot_blocked = !data.slot_blocked
        Service.put('/bb/api/pms/parking/allotment/slot_blocked_status_update/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchData();
                this.props.enqueueSnackbar('Status Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error);
            });
    }

    render() {
        return (
            <div>
                <MaterialTable
                    key={3}
                    title="Issues Raised"
                    columns={[
                        {
                            title: 'Name', field: 'name', editable: 'never'
                        },
                        {
                            title: 'Date', field: 'date_to_block', type: 'date', editable: 'never'
                        },
                        {
                            title: 'Slot Name', field: 'slot', editable: 'never'
                        },
                        {
                            title: 'Reason', field: 'reason_type', editable: 'never',
                            lookup: { 0: 'Blocked for other reasons', 1: 'Someone else parked', 2: 'Allocated to 2 wheelers', 3: 'Other issues' }
                        },
                        {
                            title: 'Blocked Status', field: 'slot_blocked',
                            lookup: { true: 'Blocked', false: 'Solved' }
                        }
                    ]}
                    data={this.state.data}
                    isLoading={this.state.isLoading}
                    options={{
                        // headerStyle: {
                        //     backgroundColor: '#007681',
                        //     color: '#FFF'
                        // },
                        actionsColumnIndex: -1,
                        paging: false,
                        padding: "dense",
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],

                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return { ...prevState, data };
                                    });
                                    this.putData(newData);
                                }, 100);
                            }),
                    }}
                />
            </div>
        )
    }
}
export default withSnackbar(IssuesRised)