import React, { Component } from 'react'
import MaterialTable from 'material-table';
import Service from '../Networking/networkutils'
import errorMessage from '../Common/ErrorMessage';
import { getCookie } from '../Common/CookieHelper';
import { withSnackbar } from 'notistack';

const token = getCookie("bb_ts_token");
class ParkingSlots extends Component {
    constructor(props) {
        super(props)

        this.state = {
            slotsData: [],
            isLoading: true,
            slotName: ''
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData() {
        Service.get(`/bb/api/pms/parking/details/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    slotsData: res.data,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }
    openDialog() {
        this.setState({ openDialogue: true })
    }

    handleDataChange(e) {
        if (e.target.id === 'slot name') {
            this.setState({ slotName: e.target.value })
        }
    }

    postOps(newData, resolve) {
        const user = JSON.parse(getCookie("bb_ts_user"))
        const User_Id = user.id
        newData.created_by = User_Id;
        Service.post(`/bb/api/pms/parking/details/`, {
            headers: {
                Authorization: "Token " + token,
            },
            data: newData
        })
            .then(res => {
                // this.setState({ openDialogue: false, slotsData: res.data })
                this.fetchData();
                this.props.enqueueSnackbar('Parking Slot Added Successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            })
            .finally(() => {
                resolve();
            });
    }
    putOps(data) {
        Service.put('/bb/api/pms/parking/details/' + data.id, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                this.fetchData();
                this.props.enqueueSnackbar('Slot Name Updated Successfully!', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error);
            });
    }
    // deleteData(data) {
    //     Service.delete('/bb/api/pms/parking/details/' + data.id + '/', {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             this.props.enqueueSnackbar('Deleted Successfully!', {
    //                 variant: 'success'
    //             });
    //         })
    //         .catch(error => {
    //             console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }
    render() {

        return (
            <div>
                <MaterialTable
                    key={3}
                    title="Parking Slots"
                    columns={[
                        {
                            title: 'Slot Name', field: 'parking_name',

                        },
                        {
                            title: 'Vehicle type', lookup: { '2': 'Two Wheeler', '4': 'Four Wheeler' }, field: 'vehicle_type'

                        },
                        {
                            title: 'Parking Location', field: 'parking_location'
                        }
                    ]}
                    data={this.state.slotsData}
                    isLoading={this.state.isLoading}
                    options={{
                        headerStyle: {
                            backgroundColor: '#007681',
                            color: '#FFF'
                        },
                        actionsColumnIndex: -1,
                        paging: false,
                        padding: "dense",
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],

                    }}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((resolve) => {
                                this.postOps(newData, resolve);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    var fields = "";
                                    if (!newData.parking_name) {
                                        fields = "Slot Name";
                                    }
                                    if (fields) {
                                        this.props.enqueueSnackbar(fields + ' is Empty!', {
                                            variant: 'warning'
                                        });
                                    } else {
                                        this.setState(prevState => {
                                            const data = [...prevState.slotsData];
                                            data[data.indexOf(oldData)] = newData;
                                            return { ...prevState, data };
                                        });
                                        this.putOps(newData);
                                    }
                                }, 600);
                            }),
                    }}
                />

            </div>
        )
    }
}

export default withSnackbar(ParkingSlots)