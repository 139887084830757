export default function errorMessage(props, error) {
    if (error && error !== undefined && (error.status === 401 || (error.response && error.response.status === 401))) {
        window.location.href = "/"
        props.enqueueSnackbar('Sorry', {
            variant: 'warning'
        });
    } else {
        props.enqueueSnackbar('Something went wrong!', {
            variant: 'error'
        });
    }
}
