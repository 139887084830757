import React, { Component } from 'react'
import BookSlot from '../BookSlot/BookSlot'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Avatar, Tooltip } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import { getCookie } from '../Common/CookieHelper';
import { withStyles } from '@material-ui/core/styles';

const user = JSON.parse(getCookie("bb_ts_user"));

const useStyles = (theme) => ({
    title: {
        flexGrow: 1,
    },
    orange: {
        backgroundColor: 'orange'
    },
});

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#007681',
        },
        secondary: {
            main: '#EEBE86',
        },
    }
})

class BookingPage extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }


    render() {

        const { classes } = this.props;

        const avatarClick = (e) => {
            alert("Login Information:\nLogin Provider: BB PaRaM \nUser: " + user.last_name + ', ' + user.first_name + "\nEmail: " + user.email)
        }
        return (
            <div>
                <ThemeProvider theme={bbColorTheme}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6"  noWrap className={classes.title}>
                                <Box display="flex" alignItems="flex-end">
                                    Parking Management System<Box style={{ marginLeft: '5px' }} fontSize={10} >Powered by PaRaM</Box>
                                </Box>
                            </Typography>
                            <Tooltip title="Profile">
                                <Avatar style={{ marginright: '0px' }} onClick={e => avatarClick()} className={classes.orange}>
                                    <Box fontSize={16} fontWeight="fontWeightBold"> {user.last_name.charAt(0) + user.first_name.charAt(0)}</Box>
                                </Avatar>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Box p={1}>
                        <BookSlot />
                    </Box>
                </ThemeProvider>
            </div>
        )
    }
}

export default  withStyles(useStyles)(BookingPage)