import * as React from 'react';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Button from '@mui/material/Button';
import Service from '../Networking/networkutils';
import LookupHelper from '../Common/LookupHelper';
import moment from 'moment';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import { getCookie, setCookie } from '../Common/CookieHelper';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Slots from '../ParkingSlots/Slots';
import Switch from '@material-ui/core/Switch';
import { Alert, AlertTitle } from '@material-ui/lab';
import { AppBar, Card, IconButton, Toolbar } from '@material-ui/core';
import RaiseIssue from '../IssuesRised/RaiseIssue';
import CloseIcon from '@material-ui/icons/Close';

const token = getCookie("bb_ts_token");
const vehicle_user_details = JSON.parse(getCookie("bb_pms_userdetails"));
const user = JSON.parse(getCookie("bb_ts_user"));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class BookSlot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dates: [],
            disabledDates: [],
            minDate: this.setMinDate(),
            maxDate: this.setMaxDate(),
            data: [],
            data1: [],
            isShowTableLoading: true,
            aboutpms: false,
            rules: false,
            checked: JSON.parse(getCookie("bb_pms_userdetails")) ? JSON.parse(getCookie("bb_pms_userdetails")).auto_booking : false,
            open_panel: window.innerWidth > 1400,
            openQR: false,
            openIssue: false
        };
        this.child = React.createRef()
        this.reloadData = this.reloadData.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        if ((window.location.href.includes("/user/book"))) {
            Service.get(`/bb/api/pms/user/details/?uid=${user.id}&is_active=true`, {
                headers: {
                    Authorization: "Token " + token,
                }
            })
                .then(res1 => {
                    if (res1.data.length > 0) {
                        setCookie('bb_pms_userdetails', JSON.stringify(res1.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                        // LocalStorageHelper.setParkingDetailsUId(res1.data[0].uid);
                        // LocalStorageHelper.setcreatedBy(res1.data[0].uid);
                        this.props.enqueueSnackbar('Welcome to BB PMS', { variant: 'info' });
                        this.setState({ is_exist: true, checked: res1.data[0].auto_booking }, () => {
                            // this.createMenu()
                            this.getData();
                        })

                    } else {
                        // this.createMenu()
                        // localStorage.clear();
                        alert("Welcome to BB Parking Management System.It Seems you're not Registered to this System.So Please Contact PaRaM support Team or Visit help page in bbparam.com")
                        window.location.href = 'https://bbparam.com';
                        // this.props.enqueueSnackbar('You are not a valid user.', { variant: 'error' });

                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error && error.response && error.response.status === 400) {
                        // this.createMenu()
                        alert("Welcome to BB Parking Management System.It Seems you're not Registered to this System.So Please Contact PaRaM support Team or Visit help page in bbparam.com")
                        window.location.href = 'https://bbparam.com'
                    } else {
                        this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                    }
                })
        } else {
            this.getData();
        }

    }

    setMinDate() {
        var currenttime = moment.utc();
        var time1 = moment.utc().set({ "hour": 10, "minute": 35, "seconds": 0 });
        var set_time = moment(time1._d)
        var day = (moment().isoWeekday())
        var min_date = {}
        if (currenttime >= set_time) {
            if (day === 5) {
                min_date = new Date().setDate(new Date().getDate() + 4)
                return min_date
            } else {
                min_date = new Date().setDate(new Date().getDate() + 2)
                return min_date
            }
        } else {
            min_date = new Date().setDate(new Date().getDate() + 1)
            return min_date
        }
    }

    setMaxDate() {
        var currenttime = moment.utc();
        var time1 = moment.utc().set({ "hour": 10, "minute": 35, "seconds": 0 });
        var set_time = moment(time1._d)
        var day = (moment().isoWeekday())
        var max_date = {}
        if (currenttime >= set_time) {
            if (day === 5) {
                max_date = new Date().setDate(new Date().getDate() + 17)
                return max_date
            } else {
                max_date = new Date().setDate(new Date().getDate() + 15)
                return max_date
            }
        } else {
            max_date = new Date().setDate(new Date().getDate() + 14)
            return max_date
        }
    }

    reloadData() {
        this.getData()
    }

    getData() {
        let data = [];
        let data1 = [];
        let disabledDates = [];
        const details = JSON.parse(getCookie("bb_pms_userdetails"))

        if (details !== null) {
            const id = details.id
            var date = moment().subtract(30, 'days').calendar()
            Service.get(`/bb/api/pms/parking/allotment/?vehicle_user_details=${id}&date_of_allotment__gte=${date}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    if (res.data.length > 0) {
                        for (var i = 0; i < res.data.length; i++) {
                            var parkingName = "";
                            if (res.data[i].parking_details_data) {
                                parkingName = res.data[i].parking_details_data.parking_name;
                            }
                            if (res.data[i].date_of_allotment >= moment().format('YYYY-MM-DD')) {
                                data.push(
                                    {
                                        date_of_allotment: this.getDateofAllotmentAsString(res.data[i].date_of_allotment),
                                        parking_score: res.data[i].parking_score,
                                        id: res.data[i].id,
                                        parking_name: parkingName
                                    });
                            } else {
                                data1.push(
                                    {
                                        date_of_allotment: this.getDateofAllotmentAsString(res.data[i].date_of_allotment),
                                        parking_score: res.data[i].parking_score,
                                        id: res.data[i].id,
                                        parking_name: parkingName
                                    });
                            }

                        }
                        disabledDates = res.data.map(m => moment(m.date_of_allotment).format("DD-MM-YYYY"));
                    }
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                }).finally(() => {
                    this.setState({ data: data, data1: data1, disabledDates: disabledDates, isShowTableLoading: false });
                });
        }

    }

    putData(auto_booking) {
        vehicle_user_details.auto_booking = auto_booking
        Service.put('/bb/api/pms/user/details/' + vehicle_user_details.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: vehicle_user_details
        })
            .then(res => {
                setCookie('bb_pms_userdetails', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                if (auto_booking) {
                    this.props.enqueueSnackbar('Auto Booking Enabled!', { variant: 'success' });
                } else {
                    this.props.enqueueSnackbar('Auto Booking Disabled!', { variant: 'success' });
                }
                this.setState({ checked: auto_booking })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })
    }
    getDateofAllotmentAsString = (date) => {
        return moment(date).format("YYYY-MM-DD");
    }
    openAboutPMS() {
        this.setState({ aboutpms: true })
    }
    openRules() {
        this.setState({ rules: true })
    }

    bookSlot = () => {
        const details = JSON.parse(getCookie("bb_pms_userdetails"))
        const id = details.id
        const user_id = details.uid
        var selectedDates = this.state.dates.map(m => new Date(m.toDate()));
        if (selectedDates.length > 0) {
            var data = [];
            var parking_details = id;
            var created_by = user_id;
            for (var i = 0; i < selectedDates.length; i++) {
                data.push(
                    {
                        date_of_allotment: this.getDateofAllotmentAsString(selectedDates[i]),
                        vehicle_user_details: parking_details,
                        created_by: created_by
                    });

            }
            Service.post('/bb/api/pms/parking/allotment/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data
            })
                .then(res => {
                    this.getData();
                    this.props.enqueueSnackbar('Data updated successfully', { variant: 'success' });
                    this.setState({ dates: [] })
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                }).finally(() => {

                });
        } else {
            this.props.enqueueSnackbar('Please select at least one date to book a slot.', { variant: 'info' })
        }
    }

    checkCancelBooking = (oldData) => {
        // var moment1 = require('moment-timezone');
        var time = moment(oldData.date_of_allotment).set({ "hour": 12, "minute": 0, "seconds": 0 });
        var set_time = moment(time._d)
        // var moment1 = require('moment-timezone');
        var date = moment.utc()
        // console.log(set_time.toString(), date.toString())
        if (set_time >= date) {
            return true
        } else {
            return false
        }
    }

    cancelBooking = (oldData, resolve) => {
        var time = moment(oldData.date_of_allotment).set({ "hour": 12, "minute": 0, "seconds": 0 });
        var set_time = moment(time._d)
        var date = moment.utc()
        if (set_time >= date) {
            Service.get(`/bb/api/pms/parking/allotment/user_deletion/?id=${oldData.id}`, {
                headers: {
                    Authorization: "Token " + token,
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Data deleted successfully!', { variant: 'success' });
                    this.getData();
                    if (this.child && this.child.current) {
                        this.child.current.getCurrentdateData()
                    }
                })
                .catch(error => {
                    if (error && error.response && error.response.status === 406) {
                        this.props.enqueueSnackbar(
                            error.response.data.message, { variant: 'warning' });
                    }
                    else {
                        this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                    }
                })
                .finally(() => {
                    resolve();
                });

        } else {
            this.props.enqueueSnackbar('Sorry!..You Cannot Delete', {
                variant: 'info'
            });
            resolve()
        }

    }

    setDates = (e) => {
        this.setState({
            dates: e
        });
    }

    screenSizeCheck() {
        // if (window.innerWidth > 1800) {
        //     return true
        // } else {
        return false
        // }

    }

    sendTitle() {
        var currenttime = moment.utc();
        var time1 = moment.utc().set({ "hour": 10, "minute": 35, "seconds": 0 });
        var set_time1 = moment(time1._d)
        var date = ''
        if (set_time1 < currenttime) {
            date = moment().utc().add(1, 'days').format('YYYY-MM-DD')
            return date
        } else {
            date = moment().utc().format('YYYY-MM-DD')
            return date
        }
    }

    issueRaise() {
        this.setState({ openIssue: true })
    }

    refreshData() {
        if (this.child && this.child.current) {
            this.child.current.getCurrentdateData()
        }
        this.getData()
    }

    render() {
        const handleChange = (event) => {
            this.putData(event.target.checked)
            // this.setState({ checked: event.target.checked })
        };
        const handlePanelChange = (event, newExpanded) => {
            this.setState({
                open_panel: newExpanded
            })
        };
        const handleDialogueClose = () => {
            this.setState({ aboutpms: false, rules: false })
        };
        const handleQRClose = () => {
            this.setState({ openQR: false })
        };
        const handleIssueDialogueClose = () => {
            this.setState({ openIssue: false })
        }
        return (
            <div>

                <Box>
                    <Alert severity="info" action={
                        <Box display="flex" flexDirection={"column"}>
                            <Button color="inherit" variant="outlined" size="small" onClick={() => window.open('https://bbparam.com/tutorials')}>
                                Tutorials
                            </Button>
                            <Button color="inherit" style={{ marginTop: "5px" }} variant="outlined" size="small" onClick={() => { this.setState({ openQR: true }) }}>
                                QR Code
                            </Button>
                        </Box>
                    }>
                        <AlertTitle><strong>Telegram Bot </strong>- (Bot Name: BB India PMS, Bot ID: <strong>bb_india_pmsbot</strong>)</AlertTitle>
                        We are happy to announce <strong>Telegram bot</strong> for PMS. With this you can Book, Cancel and View your slot. You have to contact Ramar, Raja or Madan, Rajan to get login access to bot. All <strong>"How to do?"</strong> videos are uploaded to PaRaM Tutorials.
                    </Alert>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Box sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>Welcome to Balfour Beatty - Parking Management System</Box>
                </Box>
                <Box display="flex" justifyContent="center" m={1}>
                    <Box sx={{ fontWeight: 'bold', textAlign: 'center' }}>N-1 Balsa Block, Embassy Manyata Business Park, Bangalore – 560045.</Box>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Accordion style={{ backgroundColor: '#ffffb3', width: '100%' }} expanded={this.state.open_panel} onChange={handlePanelChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography> Click here to view slot status - {this.sendTitle()}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Slots ref={this.child} callback={() => this.reloadData()} />
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box display="flex" justifyContent="center" >
                    <h2>Request Slots</h2>
                </Box>
                <Box flexDirection="row" display="flex" justifyContent="center">
                    <Box marginTop='10px' display="flex" justifyContent="center" >
                        <DatePicker
                            id="bookingDates"
                            disabledDates={this.state.disabledDates}
                            value={this.state.dates}
                            onChange={this.setDates}
                            inputClass="custom-input"
                            format="DD-MM-YYYY"
                            minDate={this.state.minDate}
                            maxDate={this.state.maxDate}
                            sort
                            placeholder="Select Dates"
                            plugins={[
                                <DatePanel />
                            ]}
                            mapDays={({ date }) => {
                                var dt = new Date(date);
                                var datestring = moment(dt).format("DD-MM-YYYY");
                                if (dt.getDay() === 6 || dt.getDay() === 0) {
                                    return {
                                        disabled: true
                                    }
                                }
                                // var time_check = moment(dt).format()
                                // if (time_check === moment().format()) {
                                //     var time = moment().format("HH")
                                //     var date1 = ''
                                //     if (time >= 16) {
                                //         if (dt.getDay() === 6 || dt.getDay() === 0) {
                                //             date1 = moment().add(1, 'days').format('DD-MM-YYYY')
                                //         } else {
                                //             console.log(dt.getDay())
                                //             date1 = moment().add(3, 'days').format('DD-MM-YYYY')
                                //         }
                                //         if (date1) {
                                //             console.log(date1)
                                //             return {
                                //                 disabled: true
                                //             }
                                //         }
                                //     }
                                // }
                                var isSelected = this.state.disabledDates.includes(datestring);
                                if (isSelected) {
                                    return {
                                        disabled: true,
                                        style: { color: "red" },
                                        onClick: () => {
                                            this.props.enqueueSnackbar('This date already booked !', { variant: 'info' });
                                        }
                                    }
                                } else {
                                    var maxDate = moment(this.state.maxDate).format("YYYY-MM-DD");
                                    var minDate = moment(this.state.minDate).format("YYYY-MM-DD");
                                    var curDate = moment(dt).format("YYYY-MM-DD");
                                    var isBetween = moment(curDate).isBetween(minDate, maxDate);
                                    if (isBetween) {
                                        return {
                                            style: { color: "green" }
                                        }
                                    }

                                }
                            }}
                        /></Box>
                    <Box display="flex" justifyContent="center" marginLeft='20px'>
                        <Button onClick={this.bookSlot} size="small" variant="contained" style={{ backgroundColor: "#007681" }}>Request</Button>
                    </Box>

                </Box>
                <Box marginTop='10px' flexDirection="row" display="flex" justifyContent="center">
                    <Box display="flex" justifyContent="center" marginRight='15px' >
                        <Button onClick={e => this.openAboutPMS()} size="small" style={{ color: "#007681" }}>About PMS</Button>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box display="flex" justifyContent="center" marginLeft='15px' marginRight='15px'>
                        <Button onClick={e => this.openRules()} size="small" style={{ color: "#007681" }}>Rules of Queue</Button>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box display="flex" justifyContent="center" marginLeft='15px'>
                        <Button onClick={e => this.issueRaise()} size="small" style={{ color: "#007681" }}>Raise Issue</Button>
                    </Box>
                </Box>
                <Box flexDirection="row" display="flex" justifyContent="center">
                    <Box marginTop='10px'>Auto Booking</Box>
                    <Box marginTop='5px' >
                        <Switch
                            checked={this.state.checked}
                            onChange={handleChange}
                            name="checkedA"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Box>
                </Box>
                <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={this.screenSizeCheck() ? 6 : 12}>
                        <MaterialTable
                            title="Upcoming"
                            columns={[
                                { title: 'Date of Booking', field: 'date_of_allotment' },
                                { title: 'Slot', field: 'parking_name' },
                                { title: 'Fair Usage', field: 'parking_score', type: 'numeric', lookup: LookupHelper.getFairUsageLookup() }
                            ]}
                            data={this.state.data}
                            options={{
                                filtering: true,
                                headerStyle: {
                                    backgroundColor: '#007681',
                                    color: '#FFF'
                                },
                                paging: false,
                                search: false,
                                pageSize: 20,
                                actionsColumnIndex: -1,
                            }}
                            isLoading={this.state.isShowTableLoading}
                            editable={{
                                isDeletable: (rowData) => this.checkCancelBooking(rowData),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        this.cancelBooking(oldData, resolve);
                                    })
                            }}
                        />
                    </Grid>
                    <Grid item xs={this.screenSizeCheck() ? 6 : 12}>
                        <MaterialTable
                            title="History"
                            columns={[
                                { title: 'Date of Booking', field: 'date_of_allotment' },
                                { title: 'Slot', field: 'parking_name' },
                                { title: 'Fair Usage', field: 'parking_score', type: 'numeric', lookup: LookupHelper.getFairUsageLookup() }
                            ]}
                            data={this.state.data1}
                            options={{
                                filtering: true,
                                headerStyle: {
                                    backgroundColor: '#007681',
                                    color: '#FFF'
                                },
                                paging: false,
                                search: false,
                                pageSize: 20,
                                actionsColumnIndex: -1,
                            }}
                            isLoading={this.state.isShowTableLoading}
                        // editable={{
                        //     isDeletable: (rowData) => this.checkCancelBooking(rowData),
                        //     onRowDelete: (oldData) =>
                        //         new Promise((resolve) => {
                        //             this.cancelBooking(oldData, resolve);
                        //         })
                        // }}
                        />
                    </Grid>
                </Grid>



                <div>
                    <Dialog fullWidth={true} maxWidth='sm' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.aboutpms} TransitionComponent={Transition}>
                        <DialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                            About PMS
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box flexDirection="row" display="flex" marginLeft='10px'>
                                <ul>
                                    <li>Each user to register with us to access the PMS app</li>
                                    <li>Director and Team Leads will have one dedicated car park slots alloted</li>
                                    <li>Normal users can request car slots for next 10 days (at the max)</li>
                                    <li>Available slots will be allocated to the users who have registered with us for a car park request everyday at 4pm</li>
                                    <li>If any user has already applied for leave within the next 10 days, system will consider that their slot is free, and allocate to another user who is in the queue</li>
                                    <li>User can release the car slot upto 9am on a specific day, immediately the released slot will be allocated to the user in queue</li>
                                    <li>After 4pm, if any unallocated slots are available, users shall book it by using the chart</li>
                                </ul>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box display="flex" justifyContent="center" marginTop="5px" marginBottom="10px">
                                <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={handleDialogueClose}>close</Button>
                            </Box>
                        </DialogActions>
                    </Dialog>
                    <Dialog fullWidth={true} maxWidth='sm' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.rules} TransitionComponent={Transition}>
                        <DialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                            Rules of Queue
                        </DialogTitle>
                        <DialogContent dividers>
                            <Box flexDirection="row" display="flex" marginLeft='10px'>
                                <ul>
                                    <li>Every month each users priority ranking will be reset and starts from scratch i.e, everyone will be at the same level eligibility</li>
                                    <li>Admin will check for the fair car park everyday around 12pm and mark for any unfair practises such as - wrong slot parking, did not turn up to office but blocked the slot, etc</li>
                                    <li>Any unfair practise will result in negative point to the user that will automatically result in lower priority</li>
                                    <li>User with maximum points will get highest priority and lower points follow then</li>
                                    <li>Each user can opt for one preferred slot, and whenever that slot is available - it will be allocated to that user as per the priority queue</li>
                                    <li>Equal priority users will get the slot equally shared throughout the month</li>
                                </ul>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box display="flex" justifyContent="center" marginTop="5px" marginBottom="10px">
                                <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={handleDialogueClose}>close</Button>
                            </Box>
                        </DialogActions>
                    </Dialog>

                    <Dialog maxWidth='sm' onClose={handleQRClose} aria-labelledby="customized-dialog-title" open={this.state.openQR} TransitionComponent={Transition}>
                        <DialogTitle id="customized-dialog-title" onClose={handleQRClose}>
                            Telegram Bot QR
                        </DialogTitle>
                        <DialogContent dividers>
                            <Card>
                                {/* <CardMedia
                                    image={process.env.PUBLIC_URL + '/Images/BotQR.png'}
                                    title="Paella dish"
                                    height={'300px'}
                                /> */}
                                <img src={process.env.PUBLIC_URL + '/Images/BotQR.png'} alt='botqr' title='BotQR' height={'500px'} />
                            </Card>
                        </DialogContent>

                    </Dialog>

                    <Dialog fullWidth={true} maxWidth='md' aria-labelledby="customized-dialog-title" open={this.state.openIssue} TransitionComponent={Transition}>
                        <AppBar position="static" color="primary">
                            <Toolbar>
                                <Box display='flex' flexDirection={'row'} width={'100%'}>
                                    <Box flexGrow={1}>
                                        <Typography variant="subtitle1" >
                                            Issue Raise
                                        </Typography>
                                    </Box>

                                    <IconButton edge="start" size='small' color="inherit" onClick={handleIssueDialogueClose} aria-label="close">
                                        <CloseIcon fontSize='small' />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent >
                            <RaiseIssue callback={() => this.refreshData()} />
                        </DialogContent>
                    </Dialog>
                </div>
            </div >
        );
    }
}
export default (withSnackbar(BookSlot));