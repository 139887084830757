import { CircularProgress, Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import moment from 'moment'
import { withSnackbar } from 'notistack';
import React, { Component } from 'react'
import { getCookie } from '../Common/CookieHelper';
import Service from '../Networking/networkutils'
import { Button, FormControl, InputLabel, MenuItem, Select, } from '@material-ui/core'
import Slots from '../ParkingSlots/Slots';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));

class RaiseIssue extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isExists: false,
            slotDetails: null,
            status: null,
            isLoading: true,
            issueRaised: false,
            slots: []
        }
        this.reloadData = this.reloadData.bind(this);
    }

    componentDidMount() {
        this.checkUserExists()
    }

    checkUserExists() {
        var date = moment().utc().format('YYYY-MM-DD')
        Service.get(`/bb/api/pms/parking/allotment/?date_of_allotment=${date}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (user.id === element.vehicle_user_details_data.user_data.id) {
                        this.setState({ isExists: true, slotDetails: element })
                        break
                    }
                }
                this.setState({ isLoading: false })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })
    }

    issueRaise() {
        var time = moment().utc().set({ "hour": 10, "minute": 30, "seconds": 0 });
        var set_time = moment(time._d)
        var date = moment.utc()
        if (this.state.status === null) {
            this.props.enqueueSnackbar('Select an Issue!', { variant: 'warning' });
        } else if (set_time <= date) {
            this.props.enqueueSnackbar('Sorry!, You cannot raise issue after 4 PM', { variant: 'warning' });
        } else {
            this.setState({ isLoading: true })
            Service.get(`/bb/api/pms/parking/allotment/issue_raise_in_ui/?vehicle_uid=${this.state.slotDetails.vehicle_user_details}&reason_type=${this.state.status}`, {
                headers: {
                    Authorization: "Token " + token,
                }
            })
                .then(res => {
                    this.setState({ issueRaised: true, slots: res.data, isLoading: false })
                    this.props.callback()
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    if (error && error.response && error.response.status === 406) {
                        if (error.response.data?.slots) {
                            this.setState({ issueRaised: true, slots: error.response.data })
                            this.props.callback()
                        }
                        this.props.enqueueSnackbar(error.response.data.message, { variant: 'warning' });
                    } else {
                        this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                    }

                })
        }
    }

    reloadData() {
        this.props.callback()
    }

    render() {
        const handleStatusChange = (event) => {
            this.setState({
                status: event.target.value
            })
        };
        return (
            <div>
                {this.state.isLoading ? <CircularProgress /> :
                    this.state.issueRaised ?
                        <Box display='flex' flexDirection='column'>
                            <Typography variant='subtitle1'>
                                {this.state.slots.message}
                            </Typography>
                            <Box>
                                {this.state.slots.slots.length !== 0 ? <Slots data={this.state.slots.slots} callback={() => this.reloadData()} /> : null}
                            </Box>
                        </Box>
                        :
                        this.state.isExists ?
                            <Box display='flex' flexDirection='column'>
                                <Typography variant='body2'>
                                    You have allocated {<b>{this.state.slotDetails.parking_details_data.parking_name}</b>} slot today{"(" + moment().utc().format('DD-MM-YYYY') + ")"}. Do you have any issue with that? If yes, choose the following below and raise an issue.
                                </Typography>
                                <FormControl fullWidth style={{ marginTop: '3px' }}>
                                    <InputLabel fullWidth id="demo-simple-select-label">Select an Issue</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        fullWidth
                                        value={this.state.status}
                                        onChange={handleStatusChange}>
                                        <MenuItem value={1}>Someone else parked</MenuItem>
                                        <MenuItem value={3}>Other issues</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box display={'flex'} flexDirection='row-reverse' mt={1}>
                                    <Button color='primary' size='small' onClick={() => this.issueRaise()}>Raise</Button>
                                </Box>
                            </Box>
                            :
                            <Typography variant='body2'>Sorry! You have no slot allocated today</Typography>}
            </div>
        )
    }
}
export default withSnackbar(RaiseIssue)