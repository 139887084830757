import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';

// import ReactBreakpoints from 'react-breakpoints'

// const breakpoints = {
//   mobile: 320,
//   mobileLandscape: 480,
//   tablet: 768,
//   tabletLandscape: 1024,
//   desktop: 1200,
//   desktopLarge: 1500,
//   desktopWide: 1920,
// }

ReactDOM.render(
  <SnackbarProvider maxSnack='3'>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </SnackbarProvider>, document.getElementById('root')
);

reportWebVitals();
