// The axios wrapper
import axios from "axios";

// const domain = 'http://santhosh-dev.centralindia.cloudapp.azure.com:8000';
const domain = 'https://api.bbparam.com'

class Service {
  constructor() {
    let service = null;

    service = axios.create({
      headers: {
        // Authorization: this.getAccessToken(),
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.state = {
      statusCode: null
    };
  }

  async getAccessToken() {
    try {
      const value = await localStorage.getItem("id_token");
      if (value !== null) {
        return value;
      } else {
        return "";
      }
    } catch (error) {
      return "";
    }
  }

  handleSuccess(response) {
    return response;
  }

  get(path, options) {
    return this.service
      .get(domain + path, {
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  patch(path, options) {
    return this.service
      .request({
        method: "PATCH",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  update(path, options) {
    return this.service
      .request({
        method: "UPDATE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  post(path, options) {
    return this.service
      .request({
        method: "POST",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  put(path, options) {
    return this.service
      .request({
        method: "PUT",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  delete(path, options) {
    return this.service
      .request({
        method: "DELETE",
        url: domain + path,
        ...options
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}

export default new Service();
