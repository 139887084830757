const LookupHelper = {

    getFairUsageLookup: () => {
        return {
            "1": 'Fair', "-1": 'Unfair'
        };
    },

    getLookupByData: (data, key, value) => {
        var lookup = {};
        for (var i = 0; i < data.length; i++) {
            lookup[[data[i][key]]] = data[i][value];
        }
        return lookup;
    }
}

export default LookupHelper;