import * as React from 'react';
import Service from '../Networking/networkutils';
import moment from 'moment';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import LookupHelper from '../Common/LookupHelper';
import { getCookie } from '../Common/CookieHelper';
import Switch from '@material-ui/core/Switch';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Slots from '../ParkingSlots/Slots';



const token = getCookie("bb_ts_token");
var access = JSON.parse(getCookie("bb_ts_access"))

class FaireUsage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isShowTableLoading: true,
            data1: [],
            checked: true,
            open_panel: window.innerWidth > 1400
        };
        this.reloadData = this.reloadData.bind(this);
    }
    getData() {
        var data = [];
        var data1 = []
        const user = JSON.parse(getCookie("bb_ts_user"));
        // const id = details.id
        const user_id = user.id
        var currentdate = moment().format('YYYY-MM-DD')
        var date1 = moment().subtract(15, 'days').format('YYYY-MM-DD')
        var date2 = moment().add(7, 'days').format('YYYY-MM-DD')
        Service.get(`/bb/api/pms/parking/allotment/?date_of_allotment=${currentdate}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                if (res.data.length > 0) {
                    var created_by = user_id;
                    for (var i = 0; i < res.data.length; i++) {
                        var parkingName = "";
                        var parking_score = 0;
                        // var parking_details = null
                        // if (res.data[i].date_of_allotment === moment().format('YYYY-MM-DD')) {
                        if (res.data[i].parking_details_data) {
                            parkingName = res.data[i].parking_details_data.parking_name;
                        }
                        if (res.data[i].parking_score) {
                            parking_score = res.data[i].parking_score;
                        } if (res.data[i].vehicle_user_details_data) {
                            var parking_details = res.data[i].vehicle_user_details_data;
                        }
                        data1.push(
                            {
                                date_of_allotment: this.getDateofAllotmentAsString(res.data[i].date_of_allotment),
                                parking_score: parking_score,
                                parking_name: parkingName,
                                id: res.data[i].id,
                                vehicle_user_details_data: parking_details,
                                vehicle_user_details: res.data[i].vehicle_user_details,
                                created_by: created_by
                            });

                    }
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }).finally(() => {
                this.setState({ data1: data1, isShowTableLoading: false });
            });

        Service.get(`/bb/api/pms/parking/allotment/?date_of_allotment__gte=${date1}&date_of_allotment__lte=${date2}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                if (res.data.length > 0) {
                    var created_by = user_id;
                    for (var i = 0; i < res.data.length; i++) {
                        var parkingName = "";
                        var parking_score = 0;
                        if (res.data[i].parking_details_data) {
                            parkingName = res.data[i].parking_details_data.parking_name;
                        }
                        if (res.data[i].parking_score) {
                            parking_score = res.data[i].parking_score;
                        } if (res.data[i].vehicle_user_details_data) {
                            var parking_details = res.data[i].vehicle_user_details_data;
                        }
                        data.push(
                            {
                                date_of_allotment: this.getDateofAllotmentAsString(res.data[i].date_of_allotment),
                                parking_score: parking_score,
                                parking_name: parkingName,
                                id: res.data[i].id,
                                vehicle_user_details_data: parking_details,
                                vehicle_user_details: res.data[i].vehicle_user_details,
                                created_by: created_by
                            });

                    }
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }).finally(() => {
                this.setState({ data: data, isShowTableLoading: false });
            });

    }
    getDateofAllotmentAsString = (date) => {
        return moment(date).format("YYYY-MM-DD");
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
    }

    updateFairUsage = (newData, oldData, resolve) => {
        newData.parking_score = parseInt(newData.parking_score)
        console.log(newData)
        console.log(oldData)
        Service.put('/bb/api/pms/parking/allotment/' + newData.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: newData
        })
            .then(res => {
                this.getData();
                this.props.enqueueSnackbar('Data updated successfully', { variant: 'success' });
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            }).finally(() => {
                resolve();
            });
    }
    setDates = (e) => {
        this.setState({
            dates: e
        });
    }

    checkDelete = (oldData) => {
        // var moment1 = require('moment-timezone');
        var time = moment(oldData.date_of_allotment).set({ "hour": 9, "minute": 0, "seconds": 0 });
        var set_time = moment(time._d)
        // var moment1 = require('moment-timezone');
        var date = moment.utc()
        // console.log(set_time.toString(), date.toString())
        if (set_time >= date) {
            return true
        } else {
            return false
        }

    }
    deleteFairUsage = (oldData, resolve) => {
        var time = moment(oldData.date_of_allotment).set({ "hour": 9, "minute": 0, "seconds": 0 });
        var set_time = moment(time._d)
        var date = moment.utc()
        if (set_time >= date) {
            Service.get(`/bb/api/pms/parking/allotment/user_deletion/?id=${oldData.id}`, {
                headers: {
                    Authorization: "Token " + token,
                }
            })
                .then(res => {
                    this.props.enqueueSnackbar('Data deleted successfully!', { variant: 'success' });
                    this.getData();
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                })
                .finally(() => {
                    resolve();
                });

        } else {
            this.props.enqueueSnackbar('Sorry!..You Cannot Delete', {
                variant: 'info'
            });
            resolve()
        }
    }

    sendTitle() {
        var currenttime = moment.utc();
        var time1 = moment.utc().set({ "hour": 10, "minute": 35, "seconds": 0 });
        var set_time1 = moment(time1._d)
        var time2 = moment.utc().set({ "hour": 7, "minute": 30, "seconds": 0 });
        var set_time2 = moment(time2._d)
        var date = ''
        if (currenttime > set_time1) {
            date = moment().add(1, 'days').format('YYYY-MM-DD')
            return date
        } else if (currenttime < set_time2) {
            date = moment().format('YYYY-MM-DD')
            return date
        } else {
            date = moment().format('YYYY-MM-DD')
            return date
        }
    }

    reloadData() {
        this.getData()
    }

    render() {
        const handleChange = (event) => {
            this.setState({ checked: event.target.checked })
        };

        const handlePanelChange = (event, newExpanded) => {
            this.setState({
                open_panel: newExpanded
            })
        };
        return (
            <div>
                <Box display="flex" justifyContent="center">
                    <Accordion style={{ backgroundColor: '#ffffb3', width: '100%' }} expanded={this.state.open_panel} onChange={handlePanelChange}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography> Click here to view slot status - {this.sendTitle()}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Slots faireusage={true} callback={() => this.reloadData()} />
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <MaterialTable
                    style={{ marginTop: '10px' }}
                    title={
                        <Box flexDirection="row" display="flex" justifyContent="center">
                            <Box marginTop='10px' display="flex" justifyContent="center" fontWeight="bold" fontSize="18px">Fair Usage </Box>
                            <Box marginTop='10px' marginLeft='50px' display="flex" justifyContent="center" >Today</Box>
                            <Box marginTop='5px' display="flex" justifyContent="center" >
                                <Switch
                                    checked={this.state.checked}
                                    onChange={handleChange}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </Box>
                        </Box>
                    }
                    columns={[
                        {
                            title: 'Employee Name', field: 'vehicle_user_details_data', editable: 'never',
                            render: rowData => rowData.vehicle_user_details_data.user_data.last_name + ", " + rowData.vehicle_user_details_data.user_data.first_name,
                            customFilterAndSearch: (term, rowData) => {
                                return  ((rowData.vehicle_user_details_data.user_data.last_name + ", " + rowData.vehicle_user_details_data.user_data.first_name).toLowerCase().includes(term.toLowerCase())) 
                            }
                        },
                        {
                            title: 'Vehicle No', field: 'vehicle_user_details_data', editable: 'never',
                            render: rowData => rowData.vehicle_user_details_data.vehicle_number,
                            customFilterAndSearch: (term, rowData) => {
                                return  (rowData.vehicle_user_details_data.vehicle_number).toLowerCase().includes(term.toLowerCase())
                            }
                        },
                        { title: 'Booking date', field: 'date_of_allotment', editable: 'never', defaultSort: 'asc' },
                        { title: 'Slot', field: 'parking_name', editable: 'never' },
                        { title: 'Fair Usage', field: 'parking_score', type: 'numeric', lookup: LookupHelper.getFairUsageLookup(), }
                    ]}
                    data={this.state.checked ? this.state.data1 : this.state.data}
                    options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        headerStyle: {
                            backgroundColor: '#007681',
                            color: '#FFF'
                        },
                        pageSize: 20,
                        paging: false,
                    }}

                    isLoading={this.state.isShowTableLoading}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                this.updateFairUsage(newData, oldData, resolve);
                            }),
                        isDeletable: (rowData) => access && access.access && access.access.l20a && access.access.l20a.l20a_c ? this.checkDelete(rowData) : true,
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                this.deleteFairUsage(oldData, resolve)
                            })
                    }}

                />
            </div >
        );
    }
}
export default (withSnackbar(FaireUsage));