import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { setCookie, getCookie } from '../Common/CookieHelper';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import BookSlot from '../BookSlot/BookSlot';
import FaireUsage from '../FaireUsage/FaireUsage';
import User from '../User/User';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import PersonIcon from '@material-ui/icons/Person';
import AddCardIcon from '@mui/icons-material/AddCard';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import Service from '../Networking/networkutils';
import { Avatar, Tooltip } from '@material-ui/core';
// import LocalStorageHelper from '../Common/LocalStorageHelper';
import ParkingSlots from '../ParkingSlots/ParkingSlots';
import { withSnackbar } from 'notistack';
import IssuesRised from '../IssuesRised/IssuesRised';
import BugReportIcon from '@material-ui/icons/BugReport';

const drawerWidth = 240;

const token = getCookie("bb_ts_token");

const useStyles = (theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    orange: {
        backgroundColor: 'orange'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});


const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

const StyledListItem = withStyles({
    root: {
        "&.Mui-selected": {
            backgroundColor: "#CFE9F8"
        }
    }
})(ListItem);

const bbColorTheme = createTheme({
    palette: {
        primary: {
            main: '#007681',
        },
        secondary: {
            main: '#EEBE86',
        },
    }
})

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewClass: '',
            openDrawer: false,
            menus: [],
            isLoading: true,
            is_exist: false
        }
    }


    componentDidMount() {
        this.idCheck()
    }

    idCheck() {
        Service.get(`/bb/api/pms/user/details/?uid=${user.id}&is_active=true`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res1 => {
                if (res1.data.length > 0) {
                    setCookie('bb_pms_userdetails', JSON.stringify(res1.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                    // LocalStorageHelper.setParkingDetailsUId(res1.data[0].uid);
                    // LocalStorageHelper.setcreatedBy(res1.data[0].uid);
                    this.props.enqueueSnackbar('Welcome to BB PMS', { variant: 'info' });
                    this.setState({ is_exist: true }, () => {
                        this.createMenu()
                    })

                } else {
                    this.createMenu()
                    // localStorage.clear();
                    // alert("Welcome to BB Parking Management System.It Seems you're not Registered to this System.So Please Contact PaRaM support Team or Visit help page in bbparam.com")
                    // window.location.href = 'https://bbparam.com';
                    // this.props.enqueueSnackbar('You are not a valid user.', { variant: 'error' });

                }
            })
            .catch(error => {
                console.log(error)
                if (error && error.response && error.response.status === 400) {
                    this.createMenu()
                    // alert("Welcome to BB Parking Management System.It Seems you're not Registered to this System.So Please Contact PaRaM support Team or Visit help page in bbparam.com")
                    // window.location.href = 'https://bbparam.com'
                } else {
                    this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
                }
            })
    }
    createMenu() {
        var accessBuild = [];
        var defaultView = '';
        var hashDone = false;

        var viewHash = window.location.hash.replace('#', '').replace('%20', ' ').replace('%20', ' ');

        if (this.state.is_exist === true) {
            if (access.access.l20a && access.access.l20a.l20a_a) {
                if (viewHash === 'Book Slots') {
                    hashDone = true;
                    this.setState({
                        viewClass: viewHash,
                    })
                }
                accessBuild.push('Book Slots')
                if (defaultView === '') {
                    defaultView = 'Book Slots'
                }
            }
        }

        if (access.access.l20a && access.access.l20a.l20a_b) {
            if (viewHash === 'Fair Usage') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Fair Usage')
            if (defaultView === '') {
                defaultView = 'Fair Usage'
            }
        }



        if (access.access.l20a && access.access.l20a.l20a_c) {

            if (viewHash === 'Management') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Management')
            if (defaultView === '') {
                defaultView = 'Management'
            }
        }

        if (access.access.l20a && access.access.l20a.l20a_c) {

            if (viewHash === 'Parking Slots') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Parking Slots')
            if (defaultView === '') {
                defaultView = 'Parking Slots'
            }
        }

        if (access.access.l20a && access.access.l20a.l20a_b) {

            if (viewHash === 'Issues Raised') {
                hashDone = true;
                this.setState({
                    viewClass: viewHash,
                })
            }
            accessBuild.push('Issues Raised')
            if (defaultView === '') {
                defaultView = 'Issues Raised'
            }
        }


        if (hashDone) {
            this.setState({
                menus: accessBuild,
            })
        } else {
            this.setState({
                menus: accessBuild,
                viewClass: defaultView
            })
        }
    }

    render() {

        const { classes } = this.props;

        const handleDrawerOpen = () => {
            this.setState({ openDrawer: true })
        };

        const handleDrawerClose = () => {
            this.setState({ openDrawer: false })
        };

        const avatarClick = (e) => {
            alert("Login Information:\nLogin Provider: BB PaRaM \nUser: " + user.last_name + ', ' + user.first_name + "\nEmail: " + user.email)
        }

        const handleViewChange = (e, val) => {

            if (!getCookie("bb_ts_token")) {
                window.location.href = '/'
            } else {
                window.location.hash = val;
                this.setState({
                    viewClass: val,
                })
            }
        };

        return (

            <ThemeProvider theme={bbColorTheme}>
                <div className={classes.root}>
                    <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: this.state.openDrawer })}>
                        <Toolbar>
                            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, { [classes.hide]: this.state.openDrawer, })}>
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap className={classes.title}>
                                <Box display="flex" alignItems="flex-end">
                                    Parking Management System<Box style={{ marginLeft: '5px' }} fontSize={10} >Powered by PaRaM</Box>
                                </Box>
                            </Typography>
                            <Tooltip title="Profile">
                                <Avatar style={{ marginright: '0px' }} onClick={e => avatarClick()} className={classes.orange}>
                                    <Box fontSize={16} fontWeight="fontWeightBold"> {user.last_name.charAt(0) + user.first_name.charAt(0)}</Box>
                                </Avatar>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>

                    <Drawer variant="permanent" className={clsx(classes.drawer, {
                        [classes.drawerOpen]: this.state.openDrawer,
                        [classes.drawerClose]: !this.state.openDrawer,
                    })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: this.state.openDrawer,
                                [classes.drawerClose]: !this.state.openDrawer,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />

                        <List>
                            {this.state.menus.map((text) => (
                                <StyledListItem button key={text} onClick={e => handleViewChange(e, text)} selected={this.state.viewClass === text} >
                                    <ListItemIcon>{text === 'Management' ? <PersonIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Parking Slots' ? <AddCardIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Fair Usage' ? <DataUsageIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Book Slots' ? <DirectionsCarIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : text === 'Issues Raised' ? <BugReportIcon color={this.state.viewClass === text ? "primary" : 'inherit'} /> : (null)}</ListItemIcon>
                                    <ListItemText primary={text} />
                                </StyledListItem>
                            ))}
                        </List>

                        <Divider />

                    </Drawer>

                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        {
                            this.state.viewClass === "Book Slots" ?
                                (<BookSlot />) :
                                this.state.viewClass === "Fair Usage" ?
                                    (<FaireUsage />) :
                                    this.state.viewClass === "Management" ?
                                        (<User />) :
                                        this.state.viewClass === "Parking Slots" ?
                                            (<ParkingSlots />) :
                                            this.state.viewClass === "Issues Raised" ?
                                                (<IssuesRised />) :
                                                null
                        }
                    </main>
                </div>
            </ThemeProvider>
        );
    }
}

export default withSnackbar(withStyles(useStyles)(Home));