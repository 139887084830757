import React, { Component } from 'react'
import Service from '../Networking/networkutils'
import errorMessage from '../Common/ErrorMessage'
import { withStyles } from '@material-ui/core/styles';
import { getCookie } from '../Common/CookieHelper'
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { CardActions, Divider } from '@mui/material';
import Button from '@mui/material/Button';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    media: {
        height: 220,
        display: 'flex',
    },
}));

class Slots extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            currentData: [],
            disableBookSlot: false,
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({ data: this.props.data })
        } else {
            this.getCurrentdateData()
        }
    }

    getCurrentdateData() {
        const token = getCookie("bb_ts_token");

        var currenttime = moment.utc();
        var time1 = moment.utc().set({ "hour": 10, "minute": 35, "seconds": 0 });
        var set_time1 = moment(time1._d)
        var date = ''
        if (set_time1 < currenttime) {
            date = moment().utc().add(1, 'days').format('YYYY-MM-DD')
        } else {
            date = moment().utc().format('YYYY-MM-DD')
        }
        const vehicles = JSON.parse(getCookie("bb_pms_userdetails"));

        Service.get(`/bb/api/pms/parking/allotment/?date_of_allotment=${date}`, {
            headers: {
                Authorization: "Token " + token,
            }
        })
            .then(res => {
                if (this.props.faireusage) {
                    this.getData(res.data, date)
                } else {
                    var disableBookSlot = false
                    for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        if (element.vehicle_user_details === vehicles.id && element.is_slot_allocated) {
                            disableBookSlot = true
                            break;
                        }
                    }
                    this.setState({ currentData: res.data, disableBookSlot: disableBookSlot })
                    this.getData(res.data, date)
                }

            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', { variant: 'error' });
            })
    }

    getData(currentData, date) {
        // var currentData = this.state.currentData
        const token = getCookie("bb_ts_token");
        Service.get(`/bb/api/pms/parking/allotment/get_slots_data/?date_of_allotment=${date}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const ele = res.data[i]
                    for (let j = 0; j < currentData.length; j++) {
                        const ele1 = currentData[j]
                        if (ele.id === ele1.parking_details) {
                            ele.userName = ele1.vehicle_user_details_data.user_data.last_name + ", " + ele1.vehicle_user_details_data.user_data.first_name
                            ele.uid = ele1.vehicle_user_details_data.user_data.id
                        }
                    }
                }
                this.setState({
                    data: res.data,
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getDateofAllotmentAsString = (date) => {
        return moment(date).format("YYYY-MM-DD");
    }

    colorchange(data) {
        if (data.vehicle_type === 0) {
            return "#ff8080"
        }
        if (data.vehicle_type === 2) {
            return "#ffb3b3"
        }
        if (data.userName) {
            return '#b3ffb3'
        } else {
            return '#d1e0e0'
        }
    }

    bookSlot(value) {
        const vehicles = JSON.parse(getCookie("bb_pms_userdetails"));
        const token = getCookie("bb_ts_token");
        Service.post(`/bb/api/pms/parking/allotment/unused_slot/?slot=${value}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                var data = this.state.data
                for (let i = 0; i < data.length; i++) {
                    const ele = data[i]
                    for (let j = 0; j < res.data.length; j++) {
                        const ele1 = res.data[j]
                        if (ele.id === ele1.parking_details) {
                            ele.userName = ele1.vehicle_user_details_data.user_data.last_name + ", " + ele1.vehicle_user_details_data.user_data.first_name
                            ele.uid = ele1.vehicle_user_details_data.user_data.id
                        }
                    }
                }

                var disableBookSlot = false
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.vehicle_user_details === vehicles.id && element.is_slot_allocated) {
                        disableBookSlot = true
                        break;
                    }
                }

                this.props.enqueueSnackbar("Slot Booked Successfully !!!", { variant: 'success' });
                this.setState({
                    data: data, currentData: res.data, disableBookSlot: disableBookSlot
                }, () => {
                    this.props.callback();
                });
            })
            .catch(error => {
                console.log(error)
                if (error && error.response && error.response.status === 406) {
                    this.props.enqueueSnackbar(
                        error.response.data.message, { variant: 'warning' });
                } else if (error && error.response && error.response.status === 403) {
                    this.props.enqueueSnackbar(
                        "This Slot is Booked by Someone else !!!", { variant: 'warning' });
                    var data = this.state.data
                    for (let i = 0; i < data.length; i++) {
                        const ele = data[i]
                        for (let j = 0; j < error.response.data.length; j++) {
                            const ele1 = error.response.data[j]
                            if (ele.id === ele1.parking_details) {
                                ele.userName = ele1.vehicle_user_details_data.user_data.last_name + ", " + ele1.vehicle_user_details_data.user_data.first_name
                                ele.uid = ele1.vehicle_user_details_data.user_data.id
                            }
                        }
                    }
                    this.setState({
                        data: data,
                    });
                } else {
                    errorMessage(this.props, error)
                }
            });
    }

    screenSizeCheck() {
        // alert(window.innerWidth)
        if (this.props.data) {
            return 6
        } else if (window.innerWidth > 1850) {
            return 2
        } else if (window.innerWidth > 1400) {
            return 3
        } else {
            return 6
        }
    }

    render() {

        const { classes } = this.props;

        return (
            <div>
                <Divider />
                <Box marginTop="10px" flexDirection="column" display="flex" padding="15px">
                    <Box display='flex'>
                        <Grid container spacing={3} className={classes.root}  >

                            {(this.state.data).map((value, i) =>
                                <Grid item xs={this.screenSizeCheck()} className={classes.root}>
                                    <Paper >
                                        <Card className={classes.root} style={{ backgroundColor: this.colorchange(value), minHeight: '100px' }}>
                                            <CardContent>
                                                <Typography gutterBottom >
                                                    {value.parking_name}
                                                </Typography>
                                                <Typography gutterBottom >
                                                    {value.userName}
                                                </Typography>
                                                <Typography gutterBottom>
                                                    {value.vehicle_type === 0 ? 'Blocked' : value.vehicle_type === 2 ? "TwoWheeler Slots" : null}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                {this.props.faireusage || value.userName || value.vehicle_type === 0 || value.vehicle_type === 2 || this.state.disableBookSlot ? (null) : (
                                                    <Box display="flex" justifyContent="center" marginRight='15px' flexDirection={'row'} width='100%'>
                                                        <Box flexGrow={1}>
                                                            <Button sx={{ fontWeight: 'bold', size: 'small' }} onClick={e => this.bookSlot(value.id)} size="small" style={{ color: "#007681" }}>Book Slot</Button>
                                                        </Box>
                                                        <Button sx={{ fontWeight: 'bold', size: 'small' }} onClick={e => window.open(value.parking_location)} size="small" style={{ color: "#007681" }}>View Map</Button>
                                                    </Box>
                                                )}
                                            </CardActions>
                                        </Card>
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    <Box display='flex' marginTop="20px" flexDirection='row' justifyContent="center">

                        <Box display='flex' marginRight="8px">
                            <span style={{ height: '35px', width: '35px', backgroundColor: '#b3ffb3', borderRadius: '50%', display: 'inline-block' }}></span>
                            <Box style={{ marginLeft: '10px', marginTop: '3px' }} fontSize={18} >- Allocated</Box>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box display='flex' marginLeft="8px" marginRight="8px" >
                            <span style={{ height: '35px', width: '35px', backgroundColor: '#d1e0e0', borderRadius: '50%', display: 'inline-block' }}></span>
                            <Box style={{ marginLeft: '10px', marginTop: '3px' }} fontSize={18} >- Unallocated</Box>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box display='flex' marginLeft="8px" marginRight="8px" >
                            <span style={{ height: '35px', width: '35px', backgroundColor: '#ff4d4d', borderRadius: '50%', display: 'inline-block' }}></span>
                            <Box style={{ marginLeft: '10px', marginTop: '3px' }} fontSize={18} >- Blocked</Box>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box display='flex' marginLeft="8px" >
                            <span style={{ height: '35px', width: '35px', backgroundColor: '#ff8080', borderRadius: '50%', display: 'inline-block' }}></span>
                            <Box style={{ marginLeft: '10px', marginTop: '3px' }} fontSize={18} >- TwoWheeler</Box>
                        </Box>

                    </Box>
                </Box>

            </div >
        )
    }
}

export default withSnackbar(withStyles(useStyles)(Slots))